@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .btn {
    @apply relative flex justify-center py-3 px-3 border border-transparent text-sm font-medium rounded-md text-white bg-black hover:bg-gray-800 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-gray-800;
  }

  a {
    @apply font-medium text-blue-600 underline hover:text-black;
  }

  /* Sidebar */
  .sidebar-item {
    @apply inline-flex items-center w-full px-4 py-2 mb-3 text-sm text-white transition duration-300 ease-in-out transform rounded-md  bg-black border-white cursor-pointer no-underline hover:text-white hover:bg-gray-800;
  }

  .sidebar-item-active {
    @apply inline-flex items-center w-full px-4 py-2 mb-3 text-sm text-black transition duration-300 ease-in-out transform rounded-md bg-white cursor-default no-underline;
  }

  .sidebar-item > .sidebar-item-active-arrow {
    visibility: hidden;
  }

  .sidebar-item-active > .sidebar-item-active-arrow {
    visibility: visible;
  }

  .form-section {
    @apply space-y-2 bg-gray-100 p-4 rounded-md w-full;
  }
}
